//기본 레이아웃값
const sampleInitialValue = {
  // 헤더값
  headerType: 'normal', // 헤더 타입 (normal, logo)
  title: '테스트', //  헤더 타이틀값
  isSticky: false, // 헤더가 항상 위에 존재하는지 유무
  btnText: '', // 텍스트 버튼 값 + 유무

  // headerType이 normal일 때만 동작
  isViewAdd: false, // 더하기 버튼 유무
  isViewAppClose: false, // 닫기 버튼 유무
  isViewInfo: false, // 정보 버튼 유무
  isViewMore: false, // 더보기 버튼 유무

  //  headerType이 logo일 때만 동작
  isViewSearch: false, // 검색 버튼 유무
  isViewAlarm: false, // 알람 버튼 유무
  hasAlarm: false, // 알람버튼이 true 일 때, 알람 유무
  // placeholder: '메뉴',
  // isClear: true,
  // auto: true,
  // isViewSearch: true,
};

export default {
  // 투데이
  PGIFPMCGuideTodayMain001: Object.assign({}, sampleInitialValue, {
    headerType: 'logo',
    btnText: '송금',

    isViewSearch: true, // 검색 버튼 유무
    isViewAlarm: true, // 알람 버튼 유무
    hasAlarm: true,
  }),

  // 로그인
  PGIFPMCGuideLoginMain001: Object.assign({}, sampleInitialValue, {
    headerType: 'logo',
    btnText: '송금',

    isViewSearch: true, // 검색 버튼 유무
    isViewAlarm: true, // 알람 버튼 유무
    hasAlarm: true,
  }),

  // 마이
  PGIFPMCGuideMyMain001: Object.assign({}, sampleInitialValue, {
    isNotHeaderViewer: true,
  }),

  // 투데이
  PGIFPMCGuideTodayMain002: Object.assign({}, sampleInitialValue, {
    headerType: 'logo',
    btnText: '송금',

    isViewSearch: true, // 검색 버튼 유무
    isViewAlarm: true, // 알람 버튼 유무
    hasAlarm: true,
  }),
};
